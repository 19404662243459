<template>
    <div class="proContent3" style="text-align: left">
        <Pro3Com1></Pro3Com1>
        <Pro3Com2></Pro3Com2>
        <Pro3Com3></Pro3Com3>
        <Pro3Com4></Pro3Com4>
        <Pro3Com5></Pro3Com5>
        <Pro3Com6></Pro3Com6>
    </div>
</template>

<script>
import Pro3Com1 from "./Pro3Com1.vue";
import Pro3Com2 from "./Pro3Com2.vue";
import Pro3Com3 from "./Pro3Com3.vue";
import Pro3Com4 from "./Pro3Com4.vue";
import Pro3Com5 from "./Pro3Com5.vue";
import Pro3Com6 from "./Pro3Com6.vue";

export default {
    name: "ProContent3",
    components: {
        Pro3Com1,
        Pro3Com2,
        Pro3Com3,
        Pro3Com4,
        Pro3Com5,
        Pro3Com6,
    },
};
</script>

<style lang="less">
.proContent3 {
    overflow: hidden;
}
</style>
