<template>
    <div class="pro3Com1" v-if="getAdvData(29).children">
        <a :href="getAdvData(29).children[0].url" target="_blank" rel="noopener noreferrer">
            <img v-if="getAdvData(29).children[0].image" :src="getAdvData(29).children[0].image" class="p3Bg" />
            <!-- 标题 -->
            <div class="p31Title">
                <p class="p31Small">广告也可以是生活的一部分</p>
                <p class="p31Big">我们试图 做些改变</p>
            </div>
        </a>
    </div>
</template>

<script>
export default {
    name: "Pro3Com1",
    inject: ["getAdvData"],
};
</script>

<style lang="less">
.pro3Com1 {
    min-width: 1423px;
    height: 600px;
    position: relative;
    & > a {
        display: block;
        width: 100%;
        height: 100%;
    }
    .p3Bg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0;
        // transform: translateX(-50%);
        object-fit: cover;
        z-index: -1;
    }
    .p31Title {
        width: 100%;
        height: 100px;
        text-align: center;
        color: white;
        position: absolute;
        top: 200px;
        .p31Small {
            width: 100%;
            height: 50px;
            line-height: 50px;
            font-size: 29px;
            letter-spacing: 9px;
        }
        .p31Big {
            width: 100%;
            height: 50px;
            font-size: 46px;
            font-weight: 900;
            letter-spacing: 9px;
        }
    }
}
</style>
