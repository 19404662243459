<template>
    <div class="pro3Com3">
        <div class="p33Box">
            <!-- 左图 -->
            <div class="p33Left">
                <img
                    src="http://iv.okvu.cn/vugw/img/pc321.png"
                    alt=""
                    class="p33Imgs wow animate__animated animate__fadeIn"
                    data-wow-delay="0.3s"
                />
                <img
                    src="http://iv.okvu.cn/vugw/img/pc322.png"
                    alt=""
                    class="p33Icon wow animate__animated animate__fadeInLeft"
                />
                <img
                    src="http://iv.okvu.cn/vugw/img/pc323.png"
                    alt=""
                    class="p33Icon wow animate__animated animate__rollIn"
                    data-wow-delay="0.5s"
                />
                <img
                    src="http://iv.okvu.cn/vugw/img/pc324.png"
                    alt=""
                    class="p33Icon wow animate__animated animate__rollIn"
                    data-wow-delay="0.6s"
                />
                <img
                    src="http://iv.okvu.cn/vugw/img/pc325.png"
                    alt=""
                    class="p33Icon wow animate__animated animate__rollIn"
                    data-wow-delay="0.7s"
                />
                <img
                    src="http://iv.okvu.cn/vugw/img/pc326.png"
                    alt=""
                    class="p33Icon wow animate__animated animate__rollIn"
                    data-wow-delay="0.8s"
                />
                <img
                    src="http://iv.okvu.cn/vugw/img/pc327.png"
                    alt=""
                    class="p33Icon wow animate__animated animate__rollIn"
                    data-wow-delay="0.9s"
                />
                <img
                    src="http://iv.okvu.cn/vugw/img/pc328.png"
                    alt=""
                    class="p33Icon wow animate__animated animate__rollIn"
                    data-wow-delay="1s"
                />
                <img
                    src="http://iv.okvu.cn/vugw/img/pc329.png"
                    alt=""
                    class="p33Icon wow animate__animated animate__rollIn"
                    data-wow-delay="1.1s"
                />
                <img
                    src="http://iv.okvu.cn/vugw/img/pc3210.png"
                    alt=""
                    class="p33Icon wow animate__animated animate__rollIn"
                    data-wow-delay="0.4s"
                />
            </div>
            <!-- 右文 -->
            <div class="p33Right wow animate__animated animate__fadeInRight">
                <p class="pr1">我们试图</p>
                <p class="pr2">让品牌就像你身边的朋友</p>
                <p class="pr3">它如同你身边的朋友一样</p>
                <p class="pr3">在朋友圈与你一起分享故事和内容</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Pro3Com3",
    data() {
        return {};
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less">
.pro3Com3 {
    min-width: 1423px;
    height: 500px;
    margin-top: 50px;
    .p33Box {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        // 左图
        .p33Left {
            width: 35%;
            height: 100%;
            float: left;
            margin-left: 150px;
            text-align: center;
            position: relative;
            .p33Imgs {
                padding-top: 50px;
                position: absolute;
                left: 50px;
            }
            .p33Icon:nth-child(2) {
                position: absolute;
                top: 150px;
                left: 140px;
            }
            .p33Icon:nth-child(3) {
                position: absolute;
                top: 100px;
                left: 10px;
            }
            .p33Icon:nth-child(4) {
                position: absolute;
                top: 220px;
                left: 10px;
            }
            .p33Icon:nth-child(5) {
                position: absolute;
                bottom: 80px;
                left: 90px;
            }
            .p33Icon:nth-child(6) {
                position: absolute;
                bottom: 110px;
                left: 220px;
            }
            .p33Icon:nth-child(7) {
                position: absolute;
                bottom: 200px;
                left: 310px;
            }
            .p33Icon:nth-child(8) {
                position: absolute;
                top: 80px;
                left: 300px;
            }
            .p33Icon:nth-child(9) {
                position: absolute;
                top: 10px;
                left: 220px;
            }
            .p33Icon:nth-child(10) {
                position: absolute;
                top: 10px;
                left: 90px;
            }
        }
        // 右文
        .p33Right {
            width: 40%;
            height: 30%;
            float: right;
            margin-top: 150px;
            padding-left: 30px;
            .pr1 {
                width: 100%;
                height: 30px;
                line-height: 30px;
                font-weight: 900;
                font-size: 24px;
            }
            .pr2 {
                width: 100%;
                height: 50px;
                line-height: 50px;
                font-weight: 900;
                font-size: 32px;
                margin-bottom: 20px;
                margin-top: 10px;
            }
            .pr3 {
                width: 100%;
                height: 30px;
                line-height: 30px;
                font-size: 20px;
            }
        }
    }
}
</style>
