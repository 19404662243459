<template>
    <div class="pro3Com2">
        <div class="p32Box">
            <!-- 左文 -->
            <div class="p32Left wow animate__animated animate__fadeInLeft">
                <p class="pl1">我们试图</p>
                <p class="pl2">让广告的内容成为一个话题</p>
                <p class="pl3">2015年1月，朋友圈出现了第一批商业广告</p>
            </div>
            <!-- 右图 -->
            <div class="p32Right">
                <img
                    src="http://iv.okvu.cn/vugw/img/pc31.png"
                    alt=""
                    class="p32Imgs wow animate__animated animate__fadeInRight"
                />
                <img
                    src="http://iv.okvu.cn/vugw/img/pc32.png"
                    alt=""
                    class="p32Img wow animate__animated animate__fadeInRight"
                    data-wow-delay="0.9s"
                />
                <img
                    src="http://iv.okvu.cn/vugw/img/pc33.png"
                    alt=""
                    class="p32Img p32Img wow animate__animated animate__fadeInRight"
                    data-wow-delay="0.5s"
                />
                <img
                    src="http://iv.okvu.cn/vugw/img/pc34.png"
                    alt=""
                    class="p32Img wow animate__animated animate__fadeInRight"
                    data-wow-delay="0.3s"
                />
                <img
                    src="http://iv.okvu.cn/vugw/img/pc35.png"
                    alt=""
                    class="p32Img wow animate__animated animate__fadeInRight"
                    data-wow-delay="0.7s"
                />
                <img
                    src="http://iv.okvu.cn/vugw/img/pc36.png"
                    alt=""
                    class="p32Img wow animate__animated animate__fadeInRight"
                    data-wow-delay="1.1s"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Pro3Com2",
    data() {
        return {};
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less">
.pro3Com2 {
    min-width: 1423px;
    height: 500px;
    margin-top: 50px;
    .p32Box {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        // 左文
        .p32Left {
            width: 40%;
            height: 30%;
            float: left;
            margin-top: 150px;
            padding-left: 30px;
            .pl1 {
                width: 100%;
                height: 30px;
                line-height: 30px;
                font-weight: 900;
                font-size: 24px;
            }
            .pl2 {
                width: 100%;
                height: 50px;
                line-height: 50px;
                font-weight: 900;
                font-size: 32px;
                margin-bottom: 20px;
                margin-top: 10px;
            }
            .pl3 {
                width: 100%;
                height: 30px;
                line-height: 30px;
                font-size: 20px;
            }
        }
        // 右图
        .p32Right {
            width: 50%;
            height: 100%;
            float: right;
            position: relative;
            .p32Imgs {
                position: absolute;
                top: 100px;
                left: 100px;
            }
            .p32Img:nth-child(2) {
                position: absolute;
                top: 200px;
            }
            .p32Img:nth-child(3) {
                position: absolute;
                top: 200px;
                right: 100px;
            }
            .p32Img:nth-child(4) {
                position: absolute;
                top: 60px;
                right: 10px;
            }
            .p32Img:nth-child(5) {
                position: absolute;
                bottom: 100px;
                right: 20px;
            }
            .p32Img:nth-child(6) {
                position: absolute;
                bottom: 20px;
                right: 250px;
            }
        }
    }
}
</style>
